import React, { useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import { css } from '@emotion/core'
import ds from '../theme'
import Delete from '@material-ui/icons/Delete'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import MuiSelect from '../components/Dropdown'
import MuiField from './../components/MuiField'
import Hr from '../components/Hr'
import Button from './../components/Button'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import SubTitle from './../components/SubTitle'
import Body from './../components/Body1'
import Body2 from './../components/Body2'
import Paragraph from '../components/Paragraph'
import IcoBitCoin from '../images/bitcoin.png'
import IcoPayPal from '../images/paypal.png'
import ArrowDown from '../images/arrow-down-24-px.png'
import ArrowRight from '../images/arrow-right-24-px.png'
import { withSolutions, SolutionContext } from '../components/SolutionProvider';

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledOrderSummary,
  header,
  summary
} from '../components/styles/ResumoDoPeido.styles'

const SummaryItem = ({
  title,
  children,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        cursor: 'default'
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 24,
            height: 24,
            backgroundImage: isActive ? `url(${ArrowDown})` : `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginRight: ds.space(3)
          }}
        />
        <SubTitle
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 600,
            whiteSpace: 'pre-wrap'
          }}
        >
          {title}
        </SubTitle>
        <div
          style={{
            width: 22,
            height: 28,
            marginLeft: 'auto'
          }}
        >
          <Delete
            style={{
              color: 'rgba(0, 0, 0, 0.38)',
              fontSize: 32
            }}
          />
        </div>
      </Div>
      {children && (
        <Div
          style={{
            height: isActive ? 'auto' : 0,
            opacity: isActive ? 1 : 0,
            transition: isActive ? 'height 0.2s, opacity 0.2s 0.1s' : 'height 0.2s 0.1s, opacity 0.2s',
            zIndex: isActive ? 1 : -1,
            position: 'relative'
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledOrderSummary}>
      <Container>
        {false && <Div
          alignItems={'center'}
        >
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, 422 , 680, 680]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>}
        <Row
          css={header}
          mt={40}
          mb={[30]}
          justifyContent={['left', 'center']}
        >
          <Column>
            <H4>Resumo do Pedido</H4>
          </Column>
        </Row>
        <Row
          flexDirection={['column']}
          alignItems={['center']}
          mb={30}
        >
          <Div
            maxWidth={['100%', 422, 680]}
          >
            <SummaryItem
              title={`HDCloud Virtual\nMachine - hostdime.com`}
              id='finan'
            >
              Estrutura ideal para quem deseja investir pouco e ter acesso a qualidade de ponta. Servidores robustos e conectividade abundante. Os serviços têm um menor preço, já que possuímos estrutura própria nos Estados Unidos, bem com o custo para aquisição de novos equipamentos é menor que no Brasil.
            </SummaryItem>
          </Div>
        </Row>
        <Row
          flexDirection={['column']}
          alignItems={['center']}
          css={summary}
        >
          <Div
            maxWidth={['100%', 422, 680]}
            textAlign={['left']}
          >
            <Div
              flexDirection={['row']}
              alignItems={['center']}
            >
              <H5>Valor Mensal</H5>
              <Body>R$ 743,83</Body>
            </Div>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={['100%', 680]}
              style={{
                opacity: 0.5,
                margin: '0 auto',
                marginTop: 16,
                marginBottom: 16
              }}
            />
            <Div
              flexDirection={['row']}
              alignItems={['center']}
            >
              <H5>Total</H5>
              <H6>R$ 743,83</H6>
            </Div>
          </Div>
        </Row>
        <Row
          css={header}
          mt={40}
          mb={[30]}
          justifyContent={['left', 'center']}
        >
          <Column>
            <H4>Faturamento de<br /> Pagamento</H4>
          </Column>
        </Row>
        <Row>
          <Column
            width={[1]}
            mb={[50]}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiSelect
                options={[
                  {label: 'Item A', value: 0},
                  {label: 'Item B', value: 1}
                ]}
                label='Escolha um cartão existente'
                isClearable={false}
                opaque
                style={{
                  maxWidth: 328,
                  marginBottom: 10,
                  textAlign: 'left'
                }}
              />
              <Paragraph
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  maxWidth: 328,
                  textAlign: 'center'
                }}
              >
                Se você não tiver um cartão registrado ou desejar usar um novo cartão de crédito para pagar essa fatura, insira os detalhes abaixo.
              </Paragraph>
            </div>
          </Column>
        </Row>
        <Row
          justifyContent={['center']}
          flexDirection={['column']}
        >
          <Column
            width={[1]}
            mb={[36]}
          >
            <Div
              style={{
                textAlign: 'center',
                fontSize: 11.8,
                fontWeight: 600,
                lineHeight: 1.36,
                letterSpacing: 2,
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: 16,
                textTransform: 'uppercase'
              }}
            >
              dados pessoais
            </Div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiField
                variant="outlined"
                label="Primeiro nome"
                placeholder='Primeiro nome'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Último nome"
                placeholder='Último nome'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="CVV"
                placeholder='CVV'
                style={{
                  maxWidth: 328
                }}
              />
            </div>
          </Column>
          <Column
            width={[1]}
            mb={[40]}
          >
            <Div
              style={{
                textAlign: 'center',
                fontSize: 11.8,
                fontWeight: 600,
                lineHeight: 1.36,
                letterSpacing: 2,
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: 16,
                textTransform: 'uppercase'
              }}
            >
              dados do cartão
            </Div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiSelect
                options={[
                  {label: 'Item A', value: 0},
                  {label: 'Item B', value: 1}
                ]}
                label='Tipo de cartão'
                isClearable={false}
                opaque
                style={{
                  maxWidth: 328,
                  marginBottom: 10,
                  textAlign: 'left'
                }}
              />
              <MuiField
                variant="outlined"
                label="Número do cartão"
                placeholder='Número do cartão'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Data de validade"
                placeholder='Data de validade'
                style={{
                  maxWidth: 328
                }}
              />
            </div>
          </Column>
          <Column
            width={[1]}
            mb={[36]}
          >
            <Div
              style={{
                textAlign: 'center',
                fontSize: 11.8,
                fontWeight: 600,
                lineHeight: 1.36,
                letterSpacing: 2,
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: 16,
                textTransform: 'uppercase'
              }}
            >
              salvar informações do cartão
            </Div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiSelect
                options={[
                  {label: 'Sim', value: 0},
                  {label: 'Não', value: 1}
                ]}
                label='Não salvar informações '
                isClearable={false}
                opaque
                style={{
                  maxWidth: 328,
                  marginBottom: 50,
                  textAlign: 'left'
                }}
              />
              <Button
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['328px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                pagar com cartão de crédito
              </Button>
            </div>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          css={header}
          mt={40}
          justifyContent={['center']}
          flexDirection={['column']}
        >
          <Column
            mb={30}
          >
            <H5>Pagar com PayPal</H5>
          </Column>
          <Button
            schema='white'
            outlined
            selected
            variant='base'
            on='onSurface'
            width={['100%']}
            maxWidth={['248px']}
            style={{
              display: 'flex',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              margin: '0 auto',
              marginBottom: 50
            }}
          >
            <img src={IcoPayPal} />
          </Button>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          css={header}
          mt={40}
          justifyContent={['center']}
          flexDirection={['column']}
        >
          <Column
            mb={30}
          >
            <H5>Pagar com BitCoin</H5>
          </Column>
          <Button
            schema='white'
            outlined
            selected
            variant='base'
            on='onSurface'
            width={['100%']}
            maxWidth={['248px']}
            style={{
              display: 'flex',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              margin: '0 auto',
              marginBottom: 70
            }}
          >
            <img src={IcoBitCoin} />
          </Button>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Resumo do Pedido' />
      <PageTemplate
        title='Resumo do Pedido'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
