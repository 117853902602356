import React from 'react'
import PropTypes from 'prop-types';

import {
  StyledBody
} from './styles/Body1.styles'

const Body1 = ({ children, fontSize, ...props }) => (
  <StyledBody 
    as='p'
    fontSize={fontSize}
    {...props}
  >
    {children}
  </StyledBody>
)

Body1.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.array
}

Body1.defaultProps = {
  fontSize: [15.8]
}

export default Body1
