import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledOrderSummaryBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
  })
)

export const styledOrderSummary = css`
  ${styledOrderSummaryBps};
`

export const H4HeaderBps = css(
  mq({
    textAlign: ['center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 328, 328],
    textAlign: ['left'],
    marginTop: [30, 30, 0, 0]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h5 {
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
  img {
    width: 70px;
  }
`

export const summary = css`
  display: flex;
  text-align: center;
  h5 {
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin-left: auto;
  }
  h6 {
    color: rgba(0, 0, 0, 0.87);
    margin-left: auto;
  }
`