import React, { createContext, useState } from 'react'

export const SolutionContext = createContext([
  null,
  null,
])

export const withSolutions = (WrappedContent) => {
  const SolutionProvider = (props) => {
    const [isActive, setIsActive] = useState(null)
    return (
      <SolutionContext.Provider
        value={[
          isActive,
          (active) => setIsActive(active)
        ]}
      >
        <WrappedContent {...props} />
      </SolutionContext.Provider>
    )
  }

  return SolutionProvider
}
